import { Radio, Select } from 'flowbite-react';
import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import CreditCard from './CreditCard';

function PaymentMethodIndex() {
    const [selectedMethod, setSelectedMethod] = useState('credit-card');
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const handleRadioChange = (event) => {
        setSelectedMethod(event.target.value);
    };

    const handleH5Click = (method) => {
        setSelectedMethod(method);
    };

    return (
        <>
            <div className='bg-white p-2 lg:p-5 border-2 rounded-b-[40px] rounded-tr-[40px]'>
                <h3 className='capitalize font-medium text-primary-light text-base xl:text-xl'>Payment Method</h3>
                <div className='w-full h-[1px] bg-gray-300 my-3'></div>
                <div>
                    <div className='flex items-start'>
                        <div className='me-4'>
                            <Radio
                                id="credit-card"
                                name="payment-method"
                                value="credit-card"
                                checked={selectedMethod === 'credit-card'}
                                onChange={handleRadioChange}
                            />
                        </div>
                        <div className={selectedMethod === 'credit-card' ? 'text-primary-light' : ''}>
                            <h5
                                className={`text-sm xl:text-base font-medium mb-1  ${selectedMethod === 'credit-card' ? 'text-primary-light' : 'text-fontColor3'}`}
                                onClick={() => handleH5Click('credit-card')}
                            >
                                Credit or Debit Card
                            </h5>
                            {selectedMethod === 'credit-card' && (
                                <>
                                    <p className='text-fontColor3 text-xs xl:text-sm'>Add and secure cards as per RBI guidelines</p>
                                    <button onClick={openModal} className='flex justify-between items-center border-2 border-primary-light my-3 text-xs xl:text-sm text-primary-light rounded-full px-5 py-1 '>
                                        <FaPlus className='me-2' />
                                        Enter Card Detail
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='flex items-start mt-5'>
                        <div className='me-4'>
                            <Radio
                                id="net-banking"
                                name="payment-method"
                                value="net-banking"
                                checked={selectedMethod === 'net-banking'}
                                onChange={handleRadioChange}
                            />
                        </div>
                        <div className={selectedMethod === 'net-banking' ? 'text-primary-light' : ''}>
                            <h5
                                className={`text-sm xl:text-base font-medium mb-1 ${selectedMethod === 'net-banking' ? 'text-primary-light' : 'text-fontColor3'}`}
                                onClick={() => handleH5Click('net-banking')}
                            >
                                Net Banking
                            </h5>
                            {selectedMethod === 'net-banking' && (
                                <>
                                    <p className='text-fontColor3 text-xs xl:text-sm my-3'>Choose Bank</p>
                                    <div className="max-w-md ">
                                        <Select id="banks" className='rounded-full' required>
                                            <option></option>
                                            <option>United States</option>
                                            <option>Canada</option>
                                            <option>France</option>
                                            <option>Germany</option>
                                        </Select>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='flex items-start mt-5'>
                        <div className='me-4'>
                            <Radio
                                id="upi"
                                name="payment-method"
                                value="upi"
                                checked={selectedMethod === 'upi'}
                                onChange={handleRadioChange}
                            />
                        </div>
                        <div className={selectedMethod === 'upi' ? 'text-primary-light' : ''}>
                            <h5
                                className={`text-sm xl:text-base font-medium mb-1 ${selectedMethod === 'upi' ? 'text-primary-light' : 'text-fontColor3'}`}
                                onClick={() => handleH5Click('upi')}
                            >
                                UPI Apps
                            </h5>
                            {selectedMethod === 'upi' && (
                                <>
                                    <p className='text-fontColor3 text-xs xl:text-sm my-3'>Choose an Option</p>
                                    <div className='flex text-fontColor3 text-xs xl:text-sm'>
                                        <div className='me-4'>
                                            <Radio id="phonepe" name="upi-option" value="phonepe" defaultChecked />
                                        </div>
                                        <div>PhonePe</div>
                                    </div>
                                    <div className='flex text-fontColor3 text-xs xl:text-sm'>
                                        <div className='me-4'>
                                            <Radio id="google-pay" name="upi-option" value="google-pay" defaultChecked />
                                        </div>
                                        <div>Google Pay</div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='flex items-start mt-5'>
                        <div className='me-4'>
                            <Radio
                                id="cod"
                                name="payment-method"
                                value="cod"
                                checked={selectedMethod === 'cod'}
                                onChange={handleRadioChange}
                            />
                        </div>
                        <div className={selectedMethod === 'cod' ? 'text-primary-light' : ''}>
                            <h5
                                className={`text-sm xl:text-base font-medium mb-1 ${selectedMethod === 'cod' ? 'text-primary-light' : 'text-fontColor3'}`}
                                onClick={() => handleH5Click('cod')}
                            >
                                Cash on Delivery
                            </h5>
                            {selectedMethod === 'cod' && (
                                <p className='text-fontColor3 text-xs xl:text-sm my-3'>Cash and UPI accepted</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className='w-full h-[1px] bg-gray-300 my-3'></div>
                <div className='flex justify-between items-center'>
                    <p className='text-fontGreen text-sm xl:text-xl'>Order Total <span className='lg:ms-20'>₹ 797</span></p>
                    <button className='text-xs xl:text-base text-nowrap rounded-full bg-background2 text-white px-4 py-2'>Confirm Order</button>
                </div>
            </div>
            <CreditCard isOpen={isModalOpen} onClose={closeModal} />
        </>
    );
}

export default PaymentMethodIndex;
