import React, { useState } from 'react';
import { FaArrowLeft } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import cart1 from '../../../assets/home/image 30.png';
import cart2 from '../../../assets/home/Group 6.png';
import cart3 from '../../../assets/home/Group 23.png';
import star from '../../../assets/home/Star 1.png';
import star2 from '../../../assets/home/Star 5.png';
import ScrollToTopButton from '../../../components/scrolltotop/ScrollToTopButton';
import { Link } from 'react-router-dom';

function MyCart() {

    const initialData = [
        {
            title: 'Green Tea',
            description: "Green tea, revered for centuries, is more than just a drink—it's a ritual, a source of well-being, and a journey into a world of exquisite flavors. At Denad, we bring you the finest green teas, each carefully selected to provide a refreshing and invigorating experience.",
            imgUrl: cart1,
            price: '₹ 259',
            quantity: 1,
        },
        {
            title: 'Masala Tea',
            description: "Green tea, revered for centuries, is more than just a drink—it's a ritual, a source of well-being, and a journey into a world of exquisite flavors. At Denad, we bring you the finest green teas, each carefully selected to provide a refreshing and invigorating experience.",
            imgUrl: cart2,
            price: '₹ 259',
            quantity: 1,
        },
        {
            title: 'Masala Tea',
            description: "Green tea, revered for centuries, is more than just a drink—it's a ritual, a source of well-being, and a journey into a world of exquisite flavors. At Denad, we bring you the finest green teas, each carefully selected to provide a refreshing and invigorating experience.",
            imgUrl: cart3,
            price: '₹ 259',
            quantity: 1,
        },
    ];

    const [data, setData] = useState(initialData);

    const decrementQuantity = (index) => {
        const updatedData = [...data];
        if (updatedData[index].quantity > 1) {
            updatedData[index].quantity -= 1;
            setData(updatedData);
        }
    };

    const incrementQuantity = (index) => {
        const updatedData = [...data];
        updatedData[index].quantity += 1;
        setData(updatedData);
    };

    const handleRemoveItem = (index) => {
        const updatedData = data.filter((_, i) => i !== index);
        setData(updatedData);
    };

    const calculateSubtotal = () => {
        return data.reduce((total, item) => total + (parseInt(item.price.replace('₹ ', '')) * item.quantity), 0);
    };

    const SHIPPING_COST = 20;
    const subtotal = calculateSubtotal();
    const itemTotal = subtotal + SHIPPING_COST;

    return (
        <>
            <div className='relative'>
                <div className='bg-mycartBanner -z-10 absolute bg-no-repeat w-full bg-cover h-52 lg:h-[65vh] xl:h-[80vh]'></div>
                <div className='z-50 px-5 md:px-[5%] xl:px-[8%] pt-[150px] lg:pt-[300px] xl:pt-[450px] mb-10'>
                    <div className='p-4 md:p-8 rounded-3xl bg-background6'>
                        <div className='flex flex-col justify-center items-center mb-5'>
                            <h2 className='text-primary-light text-xl lg:text-2xl xl:text-3xl font-bold capitalize text-center mb-5'>Shopping Cart</h2>
                            <p className='text-fontColor3 text-center md:w-[75%] text-xs lg:text-sm'>Your shopping journey is just a few steps away! Review your selections below and proceed to checkout when you're ready.</p>
                        </div>
                        <div className='grid grid-cols-12 md:gap-10'>
                            <div className='col-span-12 lg:col-span-8'>
                                <div className='flex justify-between capitalize text-base font-semibold'>
                                    <p>My Cart</p>
                                    <p>{data.length} {data.length === 1 ? 'item' : 'items'}</p>
                                </div>
                                <div className='h-[1px] bg-black opacity-10 mt-1'></div>
                                {data.map((item, i) => (
                                    <div key={i} className='grid grid-cols-12 bg-white border-2 relative rounded-b-[20px] md:rounded-b-[40px] rounded-tl-[20px] md:rounded-tl-[40px] mt-10 lg:mt-16'>
                                        <div className='col-span-12 md:col-span-4 md:relative'>
                                            <div className='md:absolute md:bottom-10 md:-left-10'>
                                                <img className='h-[250px]' src={item.imgUrl} alt={item.title} />
                                            </div>
                                        </div>
                                        <div className='absolute border-[1px] -top-2 -right-2 text-fontColor3 bg-white'>
                                            <button className='p-1' onClick={() => handleRemoveItem(i)}><IoClose /></button>
                                        </div>
                                        <div className='col-span-12 md:col-span-8 p-5'>
                                            <div className='flex justify-between'>
                                                <p className='capitalize text-base font-medium text-primary-light'>{item.title}</p>
                                                <div>
                                                    <p className="flex">
                                                        <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star} alt="star" />
                                                        <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star} alt="star" />
                                                        <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star} alt="star" />
                                                        <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star} alt="star" />
                                                        <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star2} alt="stars" />
                                                    </p>
                                                </div>
                                            </div>
                                            <p className='text-fontColor3 mt-2 text-xs lg:text-xs xl:text-sm'>{item.description}</p>
                                            <div className='h-[1px] bg-black opacity-10 my-5'></div>
                                            <div className='grid grid-cols-12 capitalize'>
                                                <div className='col-span-4'>
                                                    <p className='mb-3 font-semibold text-sm lg:text-xs xl:text-sm'>Price</p>
                                                    <p className='mb-3 font-semibold text-primary-light'>{item.price}</p>
                                                </div>
                                                <div className='col-span-4'>
                                                    <p className='font-semibold text-sm lg:text-xs xl:text-sm'>Quantity</p>
                                                    <div className='flex justify-start'>
                                                        <div className='border-[1px] md:border-2 w-fit flex justify-center items-center rounded-full mt-2 border-primary-light'>
                                                            <button onClick={() => decrementQuantity(i)} className='text-primary-light text-sm md:text-base px-1 rounded-l-full'>-</button>
                                                            <p className='text-primary-light text-sm md:text-base px-2'>{item.quantity}</p>
                                                            <button onClick={() => incrementQuantity(i)} className='text-primary-light text-sm md:text-base px-1 rounded-r-full'>+</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-span-4'>
                                                    <p className='font-semibold text-sm lg:text-xs xl:text-sm mb-3'>Total Price</p>
                                                    <p className='mb-3 font-semibold text-primary-light'>₹ {parseInt(item.price.replace('₹ ', '')) * item.quantity}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                                <p className='flex items-center mt-3 text-primary-light text-base xl:text-lg capitalize font-medium'><FaArrowLeft className='me-3' />Continue Shopping</p>
                            </div>
                            <div className='col-span-12 lg:col-span-4 mt-5 md:mt-0'>
                                <div className='bg-white p-5 border-2 pb-10 rounded-b-[20px] md:rounded-b-[40px] rounded-tr-[20px] md:rounded-tr-[40px]'>
                                    <h5 className='text-primary-light lg:text-base xl:text-lg font-medium capitalize'>Order Summary</h5>
                                    <div className='h-[1px] bg-black opacity-10 my-3'></div>
                                    <p className='text-sm xl:text-base mb-2 font-medium'>Do you have any discount code?</p>
                                    <p className='text-xs xl:text-sm text-fontColor3 mb-5'>Only one discount code per order can be applied.</p>
                                    <div>
                                        <form action="">
                                            <div className='flex justify-between gap-2'>
                                                <input className='border-2 p-1 rounded-sm w-full text-sm' type="text" name="" id="" placeholder='Your code here' />
                                                <button className='text-white capitalize p-1 rounded-sm px-4 bg-background2 text-sm'>Apply</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='mt-5'>
                                        <h6 className='text-sm xl:text-base font-medium capitalize mb-3'>Cart Total</h6>
                                        <div className='flex justify-between items-center text-sm'>
                                            <p className='text-sm lg:text-xs xl:text-sm text-fontColor3 mb-2'>Subtotal ({data.length} {data.length === 1 ? 'item' : 'items'})</p>
                                            <p className='text-primary-light font-semibold'>₹ {subtotal}</p>
                                        </div>
                                        <div className='flex justify-between items-center text-sm'>
                                            <p className='text-sm lg:text-xs xl:text-sm text-fontColor3 mb-2'>Shipping Cost</p>
                                            <p className='text-primary-light font-semibold'>₹ {SHIPPING_COST}</p>
                                        </div>
                                        <div className='flex justify-between items-center text-sm'>
                                            <p className='text-sm lg:text-xs xl:text-sm text-fontColor3 mb-2'>Item Total (inclusive of all tax)</p>
                                            <p className='text-primary-light font-semibold'>₹ {itemTotal}</p>
                                        </div>
                                        <div className='h-[1px] bg-black opacity-10 my-3'></div>
                                        <p className='text-fontGreen font-medium mt-3 capitalize'>order total</p>
                                        <div className='flex justify-between items-center text-primary-light font-medium mt-1'>
                                            <p className='text-2xl text-fontGreen'>₹ {itemTotal}</p>
                                            <Link to={'/checkout'}>
                                                <button className='flex items-center bg-background2 p-1 px-4 text-white rounded-full text-sm lg:text-sm'>
                                                    Check Out<IoIosArrowForward className='ms-2' />
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTopButton />
        </>
    );
}

export default MyCart;
