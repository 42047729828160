import React from 'react'
import cart1 from '../../../assets/home/image 30.png';
import cart2 from '../../../assets/home/Group 6.png';
import cart3 from '../../../assets/home/Group 23.png';
import star from '../../../assets/home/Star 1.png';
import star2 from '../../../assets/home/Star 5.png';

function PreviewProductsIndex({ nextStep }) {
    const product = [
        {
            title: 'Green Tea',
            imgUrl: cart1,
            price: '₹ 259',
            totalPrice: '₹ 259',
        },
        {
            title: 'Masala Tea',
            imgUrl: cart2,
            price: '₹ 259',
            totalPrice: '₹ 259',
        },
        {
            title: 'Masala Tea',
            imgUrl: cart3,
            price: '₹ 259',
            totalPrice: '₹ 259',
        },
    ];

    return (
        <>
            <div className='bg-white p-2 lg:p-5 border-2  lg:mt-5 rounded-tr-[40px] rounded-b-[40px]'>
                <p className='capitalize text-primary-light font-medium text-base'>my order</p>
                <div className='w-full h-[1px] bg-gray-300 my-2 lg:my-4'></div>
                {product.map((item, j) => (
                    <div key={j} className='grid grid-cols-12 lg:mt-5'>
                        <div className='col-span-12 md:col-span-4 md:relative'>
                            <div className='bg-background3 border-2 rounded-tl-3xl rounded-b-3xl h-fit '>
                                <img className='lg:h-36' src={item.imgUrl} alt={item.title} />
                            </div>
                        </div>
                        <div className='col-span-12 md:col-span-8 p-2 lg:p-5'>
                            <div className='flex justify-between'>
                                <p className='capitalize text-base font-medium text-primary-light'>{item.title}</p>
                                <div>
                                    <p className="flex">
                                        <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star} alt="star" />
                                        <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star} alt="star" />
                                        <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star} alt="star" />
                                        <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star} alt="star" />
                                        <img className="ms-1 w-[15px] h-[15px] md:w-[18px] md:h-[18px]" src={star2} alt="stars" />
                                    </p>
                                </div>
                            </div>
                            <p className='text-fontColor3 mt-2 text-xs lg:text-xs xl:text-sm'>{item.description}</p>
                            <div className='h-[1px] bg-black opacity-10 my-2 lg:my-5'></div>
                            <div className='grid grid-cols-12 capitalize'>
                                <div className='col-span-4'>
                                    <p className='mb-3  text-sm lg:text-xs xl:text-sm'>Price</p>
                                    <p className='mb-3 font-medium text-primary-light'>{item.price}</p>
                                </div>
                                <div className='col-span-4'>
                                    <p className=' text-sm lg:text-xs xl:text-sm'>Quantity</p>
                                </div>
                                <div className='col-span-4'>
                                    <p className=' text-sm lg:text-xs xl:text-sm mb-3'>Total Price</p>
                                    <p className='mb-3 font-medium text-primary-light'>{item.totalPrice}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <div className='w-full h-[1px] bg-gray-300 my-2 lg:my-7'></div>
                <div className='flex justify-end'>
                    <button onClick={nextStep} className='bg-background2 text-white text-sm lg:text-base px-4 py-1 rounded-full'>Continue</button>
                </div>
            </div>
        </>
    )
}

export default PreviewProductsIndex
