import React, { useState } from 'react';
import faq from '../../assets/home/faq.png';
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";

function FaqSection() {
    const [selectedFaq, setSelectedFaq] = useState(1);

    const data = [
        {
            title: "What types of tea do you offer?",
            answer: "Domestic orders typically arrive within 3-7 business days. International orders may take 10-20 business days depending on the destination.",
        },
        {
            title: "How long will it take for my order to arrive?",
            answer: "Domestic orders typically arrive within 3-7 business days. International orders may take 10-20 business days depending on the destination.",
        },
        {
            title: "Where is your tea sourced from?",
            answer: "Domestic orders typically arrive within 3-7 business days. International orders may take 10-20 business days depending on the destination.",
        },
        {
            title: "Do you offer international shipping?",
            answer: "Domestic orders typically arrive within 3-7 business days. International orders may take 10-20 business days depending on the destination.",
        },
        {
            title: "What are your customer service hours?",
            answer: "Domestic orders typically arrive within 3-7 business days. International orders may take 10-20 business days depending on the destination.",
        },
    ];

    return (
        <>
            <div className='px-[5%] xl:px-[8%] py-10 bg-background3 lg:pt-28'>
                <h2 className='text-primary-light font-semibold text-center text-xl lg:text-2xl xl:text-3xl mb-3'>Frequently Asked Questions</h2>
                <p className='text-center text-sm xl:text-base'>Find the answer to all of our most frequently asked questions.</p>
                <div className='grid grid-cols-12 lg:gap-10 md:mt-10'>
                    <div className='col-span-12 lg:col-span-7'>
                        {data.map((item, i) => (
                            <div className='w-full' key={i}>
                                <div className='border-[1px] shadow-sm p-5 rounded-b-[20px] rounded-tl-[20px] bg-white mt-4 md:mt-8'>
                                    <div className='relative'>
                                        <button
                                            className='absolute -top-8 -right-8  border-[1px] h-8 w-8 rounded-sm flex justify-center items-center bg-white z-10'
                                            onClick={() => setSelectedFaq(selectedFaq === i + 1 ? null : i + 1)}
                                        >
                                            {selectedFaq === i + 1 ? (<FiMinus />) : (<GoPlus />)}
                                        </button>
                                    </div>
                                    <h6 className='font-medium text-sm xl:text-base 2xl:text-lg'>{item.title}</h6>
                                    <div className={` ${selectedFaq === i + 1 ? 'block' : 'hidden'}`}>
                                        <div className='border-[1px]  h-[1px] my-4'></div>
                                        <p className='text-fontColor3 text-xs xl:text-sm 2xl:text-base'>{item.answer}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='col-span-12 lg:col-span-5 lg:flex lg:justify-end'>
                        <img className='h-auto lg:h-[600px] w-full' src={faq} alt="frequently asked question" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default FaqSection;
