import React, { useRef } from 'react';
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';
import greentea from '../../assets/home/image 30.png';
import masalatea from '../../assets/home/Group 6.png';
import coffee from '../../assets/home/Group 23.png';
import "./OurProducts.css";
import ProductCard from '../../components/productCard/ProductCard';

function BestSeller() {
    const carouselRef = useRef(null);

    const items = [
        {
            name: 'Green Tea',
            imgSrc: greentea,
            mrp: '500',
            discount: '259',
            alt: 'tea cup',
            description: 'Handpicked from the best tea estates, ensuring each leaf is rich in flavor and antioxidants.',
        },
        {
            name: 'Masala tea',
            imgSrc: masalatea,
            mrp: '500',
            discount: '259',
            alt: 'tea leaf',
            description: 'Packed with antioxidants and anti-inflammatory properties, Masala Tea supports digestion and boosts immunity',
        },
        {
            name: 'Coffee',
            imgSrc: coffee,
            mrp: '500',
            discount: '259',
            alt: 'cookie',
            description: 'Coffee is a brewed beverage from roasted beans, known for its rich flavors, stimulating caffeine, and inviting aroma',
        },
    ];

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <div id='process' className='px-[5%] xl:px-[8%] py-10 relative'>
                <div>
                    <h2 className='capitalize font-bold text-primary-light text-xl lg:text-2xl xl:text-3xl mb-2 lg:mb-5 text-center'>Our Best Seller</h2>
                    <p className='text-fontColor text-center text-xs lg:text-sm xl:text-base md:mb-5'>Consistently top-rated by our customers for its flavor, quality, and health benefits.</p>
                    <div className='w-full'>
                        <Carousel
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side
                            infinite={true}
                            autoPlay={true}
                            swipeable={true}
                            draggable={true}
                            arrows={false}
                            autoPlaySpeed={2000}
                            keyBoardControl={true}
                            transitionDuration={500}
                            containerClass="carousel-container-bestseller"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            itemClass="carousel-item-padding-40-px"
                            showDots={true}
                            dotListClass="custom-dot-list"
                            ref={carouselRef}
                        >
                            {items.map((item, index) => (
                                <ProductCard key={index} item={item} />
                            ))}
                        </Carousel>
                    </div>
                </div>
                <div className="absolute bottom-16 right-28 lg:right-40 flex space-x-24 md:space-x-10">
                    <button onClick={() => carouselRef.current.previous()} className="custom-arrow">
                        <IoIosArrowBack />
                    </button>
                    <button onClick={() => carouselRef.current.next()} className="custom-arrow">
                        <IoIosArrowForward />
                    </button>
                </div>
            </div>
        </>
    );
}

export default BestSeller;
