import React from 'react'
import about from '../../assets/about/Rectangle 3.png'
import about2 from '../../assets/about/Rectangle 4.png'
import about3 from '../../assets/about/image 92.png'
import AboutGallery from './AboutGallery'
import ScrollToTopButton from '../../components/scrolltotop/ScrollToTopButton'

function AboutIndex() {
    return (
        <>
            <div className='relative'>
                <div className='bg-aboutBanner -z-10 absolute  bg-no-repeat w-full bg-cover h-52 lg:h-[65vh] xl:h-[80vh]'>
                </div>
                <div className=' z-50 px-[5%] xl:px-[8%] mb-5 md:mb-0 md:py-10 pt-[150px] lg:pt-[300px] xl:pt-[450px]'>
                    <div className='bg-background6 rounded-t-3xl p-5  lg:pb-5'>
                        <div className='py-5'>
                            <h2 className='text-center  text-2xl lg:text-3xl xl:text-3xl text-primary-light capitalize font-semibold'>about us</h2>
                            <div className='bg-fontColor3 opacity-20 w-full h-[1px] my-5 lg:my-10'></div>
                            <div className='grid grid-cols-12 md:gap-3 lg:gap-8 '>
                                <div className='col-span-12 md:col-span-5'>
                                    <img className='h-[250px] lg:h-[400px] w-full' src={about} alt="about" />
                                </div>
                                <div className='col-span-12 md:col-span-7 mt-3'>
                                    <p className='text-fontColor3 text-justify lg:leading-[30px]  xl:leading-[30px] text-xs lg:text-sm xl:text-base'>Welcome to our serene corner of the internet, where the ancient art of tea-making meets modern elegance. We are passionate tea enthusiasts dedicated to sharing the timeless joy of tea with the world. Our journey began with a simple idea: to offer the finest, most authentic teas that capture the essence of their origins. At Denad, We’re passionate about tea, and it all start with the leaves! We source only the finest organic teas, using traditional methods that care for the environment. This way, every cup tastes amazing and is good for you too. Our commitment to organic goes beyond just a label. We partner with dedicated tea gardens around the world, ensuring ethical sourcing and sustainable practices from seed to cup</p>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-12 gap-5 md:py-5'>
                            <div className='col-span-12 md:col-span-9 lg:mt-3'>
                                <h2 className='text-primary-light font-semibold lg:text-xl xl:text-2xl mb-2 md:mb-5 capitalize'>our story</h2>
                                <p className='text-fontColor3 text-justify lg:leading-[30px]  xl:leading-[30px] text-xs lg:text-sm xl:text-base'>Our story starts with a love for tea that transcends borders and cultures. From the misty mountains of Darjeeling to the lush fields of Matcha in Japan, our quest for the perfect cup has taken us far and wide. Each tea in our collection is carefully selected to ensure that it not only meets our high standards but also tells a story of tradition, craftsmanship, and dedication.</p>
                            </div>
                            <div className='col-span-12 md:col-span-3'>
                                <img src={about2} alt="" />
                            </div>
                        </div>

                    </div>
                    <div className='grid grid-cols-12 lg:gap-16 xl:gap-0 items-center bg-background2 relative px-5'>
                        <div className='col-span-4 lg:col-span-3 md:block hidden'>
                            <div className='absolute bottom-0'>
                                <img className='md:h-[220px] lg:h-[350px] xl:h-[320px]' src={about3} alt="" />
                            </div>
                        </div>
                        <div className='col-span-12 md:col-span-8 lg:col-span-9 py-5 lg:py-14'>
                            <h2 className='text-white mb-5 lg:text-2xl xl:text-3xl font-semibold capitalize'>our mission</h2>
                            <p className='text-white text-justify lg:leading-[30px]  xl:leading-[30px] text-xs lg:text-xs xl:text-base'>We believe that tea is more than just a beverage – it's an experience, a ritual, and a moment of peace in a busy world. Our mission is to provide our customers with the highest quality teas, sourced ethically and sustainably. We are committed to promoting wellness and mindfulness through the simple yet profound act of drinking tea.</p>
                        </div>
                    </div>
                    <div className='bg-background6 pb-5 rounded-b-3xl'>
                        <AboutGallery />
                    </div>
                </div>
            </div>
            <ScrollToTopButton />
        </>
    )
}

export default AboutIndex

